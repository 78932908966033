import { generateIFrame } from './iframe-common';
import InjectedData from './models/InjectedData';
import RuntimeConfig from './runtime-config';
import AnswersExperienceFrame from './answers-experience-frame';

const runtimeConfig = new RuntimeConfig();

var scriptTag = document.querySelector('script[data-css]');
var cssPath=""
if (scriptTag) {
  cssPath = scriptTag.getAttribute("data-css");
}


const answersExperienceFrame = new AnswersExperienceFrame(runtimeConfig);
window.AnswersExperienceFrame = answersExperienceFrame;

const domain = new InjectedData().getDomain();
generateIFrame(domain, answersExperienceFrame, cssPath);